<template>
    <layout-full>
        <router-view/>
        <app-customizer v-if="show"
                        slot="customizer"
        />
    </layout-full>
</template>

<script>
  import LayoutFull from '@core/layouts/layout-full/LayoutFull.vue'
  import AppCustomizer from "@/views/Documents/Editor/Sides/RightSidebar";

  export default {
    components: {
      AppCustomizer,
      LayoutFull,
    },
    data() {
      return {
        show: false
      }
    },
    beforeRouteEnter(to, from, next) {
      // called before the route that renders this component is confirmed.
      // does NOT have access to `this` component instance,
      // because it has not been created yet when this guard is called!
      if (to.meta.resource === 'Editor') {
        this.show = true
      }
    },
  }
</script>
