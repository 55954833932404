<template>
  <div
      class="rightbar-panel d-none d-md-block"
      :class="{'open': isCustomizerOpen}">
    <!-- Header -->
    <div class="rightbar-panel-section  bg-light-primary d-flex justify-content-between align-items-center px-2 py-1">
      <h6 class="section-label mb-0 text-primary">
        {{ $t('document.rightSidebar.comments') }}
      </h6>
      <div>
        <feather-icon
            size="20"
            icon="XIcon"
            class="cursor-pointer"
            @click="closeRight"/>
      </div>
    </div>
    <div class="rightbar-panel-section d-flex justify-content-between align-items-center px-2 py-1">
      <h6 class="mb-0 text-secondary">
        <span v-if="sectionId !== null">{{ getSelectedSectionHeader }}</span>
        <span v-else>{{ $t('document.rightSidebar.documentBased') }}</span>
      </h6>
      <v-select
          v-model="selectedFilter"
          :options="commentListFilterOptions"
          class="w-50 my-0"
          :label="'title'"
          :multiple="false"
      >
        <template v-slot:option="option">
          <span>{{ option.title }}</span>
        </template>
      </v-select>
    </div>
    <!-- Header -->
    <!-- Skin, RTL, Router Animation -->
    <div>
      <div class="editor-app-menu">
        <comment-list
            v-if="isCustomizerOpen"
            :filter="selectedFilter.value"
            :document-id="documentId"
            :section-id="sectionId"/>
      </div>
    </div>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import CommentList from '@/views/Documents/Editor/Sections/Comments/List';
import {PEventBus} from "@/services/PEventBus";
import useAppCustomizer from './useAppCustomizer'
import vSelect from "vue-select";
import i18n from "@/libs/i18n";

export default {
  directives: {
    Ripple,
  },
  components: {
    CommentList,
    vSelect
  },
  props: {
    documentId: String,
    workspaceId: String,
    sectionId: String
  },
  computed: {
    getSelectedSectionHeader() {
      if (this.sectionId !== null) {
        const section = this.$store.getters["document/getSectionById"](this.sectionId)
        return section.sequence + 1 + ". " + section.header
      }
      return ''
    },
  },
  data() {
    return {
      loading: false,
      tabIdx: 0,
      approversList: [],
      selectedFilter: {title: i18n.t('all'), value: 'ALL'},
      commentListFilterOptions: [
        {title: i18n.t('all'), value: 'ALL'},
        {title: i18n.t('open'), value: 'OPEN'},
        {title: i18n.t('closed'), value: 'CLOSED'}
      ]
    }
  },
  mounted() {
    PEventBus.$on('toggle-comments-panel', () => this.toggle())
    PEventBus.$on('section-selected', this.onSectionSelect)
    PEventBus.$on('handleCommentQuote', this.handleCommentQuote)
  },
  beforeDestroy() {
    PEventBus.$off('toggle-comments-panel', () => this.toggle())
    PEventBus.$off('section-selected', this.onSectionSelect)
    PEventBus.$off('handleCommentQuote', this.handleCommentQuote)
  },
  methods: {
    handleCommentQuote() {
      this.openRight()
    },
    loadSectionApprovers(sectionId) {
      this.loading = true
      this.$store.dispatch('section/fetchAllApprovers', sectionId)
          .then(response => {
            this.loading = false
            this.approversList = response
          })
    },
    toggle() {
      if (this.isCustomizerOpen) {
        this.closeRight()
      } else {
        this.openRight()
      }
    },
    documentIdFromUrl() {
      return this.$route.params.id;
    },
  },
  setup() {
    const {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,
      closeRight,
      openRight,
    } = useAppCustomizer()

    if (layoutType.value === 'horizontal') {
      // Remove semi-dark skin option in horizontal layout
      const skinSemiDarkIndex = skinOptions.findIndex(s => s.value === 'semi-dark')
      delete skinOptions[skinSemiDarkIndex]

      // Remove menu hidden radio in horizontal layout => As we already have switch for it
      const menuHiddneIndex = navbarTypes.findIndex(t => t.value === 'hidden')
      delete navbarTypes[menuHiddneIndex]
    }

    // Perfect Scrollbar
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      // Vertical Menu
      isVerticalMenuCollapsed,

      // Customizer
      isCustomizerOpen,

      // Skin
      skin,
      skinOptions,

      // Content Width
      contentWidth,
      contentWidthOptions,

      // RTL
      isRTL,

      // routerTransition
      routerTransition,
      routerTransitionOptions,

      // Layout Type
      layoutType,
      layoutTypeOptions,

      // NavMenu Hidden
      isNavMenuHidden,

      // Navbar
      navbarColors,
      navbarTypes,
      navbarBackgroundColor,
      navbarType,

      // Footer
      footerTypes,
      footerType,

      // Perfect Scrollbar
      perfectScrollbarSettings,

      closeRight,
      openRight,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
[dir] .vs--single .vs__dropdown-toggle {
  padding: 0;
}
</style>
