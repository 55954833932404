<template>
  <div style="min-height: 800px; height: 100%">
    <loading v-if="loading" :with-logo="false"></loading>
    <div class="" v-else>
      <div class="px-1 py-2 justify-content-between align-content-center"
           v-if="filteredComments.length === 0">
        <empty-placeholder/>
      </div>
      <div v-else>
        <div
            class="scroll-area pl-1 pr-1 mb-1"
            style="height: calc(65vh); overflow-y: scroll; overflow-x: hidden">
          <div class="pb-50">
          </div>
          <b-list-group v-for="(commentGroup) in filteredComments" :key="commentGroup.commentGroupId"
                        class="comment-group-box mb-1">
            <b-list-group-item class="flex-column align-items-start cursor-pointer "
                               style="margin-bottom: 10px; border: none; "
                               v-for="(comment) in commentGroup.comments"
                               :key="comment.commentId">
              <div class="row d-flex justify-content-start align-content-start"
                   :style="comment.resolved ? 'color:#929292' : '' ">
                <div class="col-2 px-50">
                  <b-avatar text="PI" variant="light-primary"/>
                </div>
                <div class="col-8 ml-auto">
                  <div :style="comment.resolved ? 'text-decoration-line: line-through;' : ''">
                    <span class="font-weight-bold">{{ comment.commenter }}</span>
                    <small class="d-flex text-gray">{{ getFormattedCreatedDate(comment.commentedAt) }}</small>
                    <div class="d-flex mt-1">
                      <div :id="`${comment.commentId}-editable-div`"
                           :ref="`${comment.commentId}-editable-div-ref`"
                           style="font-size: 14px; display: inline;  word-break: break-all; word-wrap: break-word;  height: 100%;width: 100%;max-width: 100%;"
                           :class="[editEnabledCommentId === comment.commentId ? editableCommentBoxClasses : 'font-weight-bold mb-25']"
                           :contenteditable="!(comment.deleted || comment.resolved)">
                        {{ comment.comment }}
                      </div>
                    </div>
                    <div class="d-flex" v-if="isThreadEnabled(comment.commentId)"
                         :id='`${comment.commentId}-reply-actions`'>
                      <b-button class="reply-button" variant="primary"
                                @click="replyInThread(commentGroup.commentGroupId, comment.commentId)">
                        {{ $t('section.answer') }}
                      </b-button>
                      <b-button class="cancel-button" variant="outline-danger"
                                @click.stop="cancelFocus(comment.commentId, $event)">
                        İptal
                      </b-button>
                    </div>
                    <b-link v-show="comment.quote !== null"
                            v-b-popover.hover.top="comment.quote"
                            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                            size="sm"
                            variant="flat-primary">
                      {{ $t('section.viewQuote') }}
                    </b-link>
                  </div>
                </div>
                <div style="width:auto" class="col-2 px-50">
                  <b-nav-item-dropdown
                      :disabled="comment.resolved"
                      no-caret
                      right
                      style="list-style: none; width: 120px "
                      toggle-class="d-flex align-items-center">
                    <template #button-content>
                      <b-button v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                                class="rounded-circle "
                                style="padding: 2px; z-index: 1"
                                variant="flat-secondary">
                        <div class="icon-wrapper">
                          <feather-icon icon="MoreVerticalIcon" size="14"/>
                        </div>
                      </b-button>
                    </template>
                    <b-dropdown-group header="">
                      <b-dropdown-item class="font-weight-bold" @click="resolveComment(comment.commentId)">
                        <span class="font-weight-bold">{{ $t('section.resolveComment') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="enableComment(comment)">
                        <span class="font-weight-bold">{{ $t('section.editComment') }}</span>
                      </b-dropdown-item>
                      <b-dropdown-item class="font-weight-bold" @click="deleteComment(comment.commentId)">
                        <span class="font-weight-bold">{{ $t('section.deleteComment') }}</span>
                      </b-dropdown-item>
                    </b-dropdown-group>
                  </b-nav-item-dropdown>
                </div>
              </div>
            </b-list-group-item>
            <b-list-group-item v-if="!commentGroup.resolved">
              <div class="row d-flex">
                <div style="width:auto" class="px-50">
                  <b-avatar text="CC" variant="light-primary"/>
                </div>
                <div class="col ml-auto">
                  <div class="reply-editable-area mb-50"
                       :id='`${commentGroup.commentGroupId}-reply-textarea-id`'
                       :contenteditable="!commentGroup.resolved"
                       :ref='`${commentGroup.commentGroupId}-reply-textarea`'
                       @focus="onReplyTextareaFocus(commentGroup.commentGroupId)"
                       :data-placeholder="$t('section.addThreadComment')"/>
                  <div class="ml-auto py-25" v-if="focusedCommentId === commentGroup.commentGroupId"
                       :id='`${commentGroup.commentGroupId}-reply-actions-main`'>
                    <b-button class="reply-button" variant="primary" @click="reply(commentGroup.commentGroupId)">
                      {{ $t('section.answer') }}
                    </b-button>
                    <b-button class="cancel-button" variant="outline-danger"
                              @click="cancelFocus(commentGroup.commentGroupId)">
                      {{ $t('section.cancel') }}
                    </b-button>
                  </div>
                </div>
              </div>
            </b-list-group-item>
          </b-list-group>
        </div>
      </div>
    </div>
    <div class=" pl-2 pr-2 pt-1 mb-1"
         style="background-color: white; height:150px; z-index: 98; width:100%; bottom: 0; position: absolute">
      <b-alert style="height: auto; overflow-y: scroll; position: absolute"
               variant="primary"
               dismissable
               :show="quote !=null">
        <div class="alert-heading bg-warning">
          <b class="text-white">{{ $t('section.quote') }}</b>
        </div>
        <div class="alert-body bg-warning">
          <q class="text-white">{{ quote }}</q>
        </div>
      </b-alert>
      <mentionable-textarea
          ref="mentionableTextarea"
          :placeholder="$t('section.yourComment')"
          @input="onInput"
          @select="onUserMentionSelect"
          :rows="3"
          @onMention="onMention(instance, $event)"
          :modeIdentifiers="modeIdentifiers">

      </mentionable-textarea>
      <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          class="mt-1 mb-1"
          @click="newCommentThread"
          variant="outline-primary">
        {{ $t('section.addComment') }}
      </b-button>
    </div>
  </div>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BDropdownGroup,
  BDropdownItem,
  BLink,
  BListGroup,
  BListGroupItem,
  BNavItemDropdown,
  VBPopover,
} from "bootstrap-vue";
import MentionableTextarea from "@/views/PapirComponents/MentionableUser/MentionableTextarea";
import {mapState} from "vuex";
import debounce from 'debounce';
import Ripple from "vue-ripple-directive";
import {PEventBus} from "@/services/PEventBus";
import EmptyPlaceholder from "@/views/Documents/Editor/Sections/Comments/EmptyPlaceholder";
import {userMention} from '../../../../PapirComponents/MentionableUser/MentionModes'
import Loading from "@/views/components/Loading/Loading";
import DateUtil from "@/services/DateUtil";

export default {
  name: "List",
  directives: {
    Ripple,
    'b-popover': VBPopover,
  },
  components: {
    MentionableTextarea,
    BLink,
    BListGroup,
    BListGroupItem,
    BAlert,
    EmptyPlaceholder,
    BButton,
    Loading,
    BAvatar,
    BNavItemDropdown,
    BDropdownGroup,
    BDropdownItem,
  },
  props: {
    sectionId: String,
    documentId: String,
    filter: {
      type: String,
      default: 'ALL'
    }
  },
  watch: {
    sectionId: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (val === null) {
          this.fetchDocumentComments()
        }
        if (val !== null && val !== oldVal) {
          this.fetchSectionComments()
        }
      }
    }
  },
  computed: {
    ...mapState({
      sectionComments: state => state.comment.sectionComments,
      documentComments: state => state.comment.documentComments,
      loading: state => state.comment.listLoading,
    }),
    editableCommentBoxClasses() {
      return 'd-flex w-100 font-weight-bold p-50 my-50 selected-comment-div'
    },
    comments() {
      if (this.sectionId !== null) {
        return this.sectionComments
      } else {
        return this.documentComments
      }
    },
    filteredComments() {
      switch (this.filter) {
        case 'OPEN': {
          return this.comments.filter(comment => !comment.resolved)
        }
        case 'CLOSED': {
          return this.comments.filter(comment => comment.resolved)
        }
      }
      return this.comments
    },
    filteredUserList() {
      if (this.searchParam && this.searchParam !== '') {
        return this.dummyUsers.filter(user => user.name.toLowerCase().startsWith(this.searchParam.toLowerCase()));
      }
      if (this.dummyUsers && this.dummyUsers.length > 0) {
        return this.dummyUsers;
      } else {
        return [];
      }
    },
    instance() {
      return this;
    },
    modeIdentifiers() {
      return [{...userMention, suggestions: this.filteredUserList},]
    }
  },
  data() {
    return {
      quote: null,
      comment: null,
      currentCommentId: null,
      searchParam: '',
      editMode: false,
      focusedCommentId: null,
      editEnabledCommentId: null,
      dummyUsers: [
        //{name: "mario", fullName: "Mario Speedwagon"},
      ]
    }
  },
  mounted() {
    PEventBus.$on('handleCommentQuote', (event) => this.quote = event)
    this.fetchParticipants()
  },
  methods: {
    onReplyTextareaFocus(commentId) {
      this.focusedCommentId = commentId
    },
    async isCommentGroupClosed(commentGroupId) {
      await this.$nextTick()
      let isGroupClosed = false

      for (const value of this.filteredComments) {
        isGroupClosed = (commentGroupId === value.commentGroupId) && value.resolved
        if (isGroupClosed) {
          break
        }
      }

      return isGroupClosed
    },
    isThreadEnabled(commentId) {
      return (this.editEnabledCommentId === commentId)
    },
    cancelFocus(commentKey) {
      this.focusedCommentId = null
      this.editEnabledCommentId = null
      this.$refs[`${commentKey}-editable-div-ref`][0].contentEditable = false
    },
    reply(commentKey) {
      const replyText = this.$refs[`${commentKey}-reply-textarea`][0].textContent
      this.postComment(commentKey, replyText)
    },
    replyInThread(commentKey, commentId) {
      const replyText = this.$refs[`${commentId}-editable-div-ref`][0].textContent
      if (commentId === null) {
        this.postComment(commentKey, replyText)
      } else {
        this.editComment(commentId, replyText)
      }
      this.quote = null
      this.comment = null
      this.$refs[`${commentId}-editable-div-ref`][0].textContent = ''
    },
    onInput(event) {
      console.log(event)
    },
    onUserMentionSelect(suggestion) {
      console.log(suggestion)
    },
    onMention: debounce((self, event) => {
      self.currentMode = event.mode;
      self.searchParam = event.searchParam;
    }, 150),
    fetchDocumentComments(setLoading = true) {
      if (setLoading) {
        this.$store.commit("comment/SET_LOADING", true)
      }
      this.$store.dispatch('comment/fetchDocumentComments', this.documentId)
          .then(value => this.$store.commit("comment/SET_LOADING", false))
          .catch(reason => this.$store.commit("comment/SET_LOADING", false))
    },
    fetchSectionComments(setLoading = true) {
      if (setLoading) {
        this.$store.commit("comment/SET_LOADING", true)
      }
      this.quote = null
      if (this.sectionId !== null) {
        this.$store.dispatch('comment/fetchSectionComments', this.sectionId)
            .then(value => this.$store.commit("comment/SET_LOADING", false))
            .catch(reason => this.$store.commit("comment/SET_LOADING", false))
      }
    },
    resolveComment(commentId) {
      const payload = {
        sectionId: this.sectionId,
        commentId: commentId
      }
      this.$store.dispatch('comment/resolveComment', payload)
          .then(res => {
                if (this.sectionId !== null) {
                  this.fetchSectionComments(false)
                } else {
                  this.fetchDocumentComments(false)
                }
              }
          )
    },
    enableComment(comment) {
      if (!comment.resolved) {
        this.editEnabledCommentId = comment.commentId
        this.$refs[`${comment.commentId}-editable-div-ref`][0].contentEditable = true
        this.setEndOfContentEditable(this.$refs[`${comment.commentId}-editable-div-ref`][0])

        document.execCommand('selectAll', false, null);
        document.getSelection().collapseToEnd();
      }
    },
    getFormattedCreatedDate(date) {
      return DateUtil.getCalendarDay(date).toUpperCase();
    },
    getCreatedByInfo(name) {
      return name + ' tarafından oluşturuldu.'
    },
    newCommentThread() {
      const commentText = this.$refs.mentionableTextarea.$refs.textarea.value
      this.postComment(null, commentText)
    },
    postComment(repliedToId, commentText) {
      if (commentText !== null && commentText !== "") {
        const payload = {
          repliedToId: repliedToId,
          sectionId: this.sectionId,
          documentId: this.documentId,
          comment: commentText,
          quote: this.quote,
        }
        this.$store.dispatch('comment/postComment', payload)
            .then(res => {
                  this.$refs.mentionableTextarea.$refs.textarea.value = null
                  this.comment = null
                  this.quote = null

                  if (this.sectionId !== null) {
                    this.fetchSectionComments(false)
                    this.stayFocused(`${repliedToId}-reply-textarea-id`)
                  } else {
                    this.fetchDocumentComments(false)
                  }
                }
            ).then(res => this.fetchSectionComments())
      }
    },
    fetchParticipants() {

    },
    setEndOfContentEditable(contentEditableElement) {
      let range, selection;
      if (document.createRange)//Firefox, Chrome, Opera, Safari, IE 9+
      {
        range = document.createRange();//Create a range (a range is a like the selection but invisible)
        range.selectNodeContents(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        selection = window.getSelection();//get the selection object (allows you to change selection)
        selection.removeAllRanges();//remove any selections already made
        selection.addRange(range);//make the range you have just created the visible selection
      } else if (document.selection)//IE 8 and lower
      {
        range = document.body.createTextRange();//Create a range (a range is a like the selection but invisible)
        range.moveToElementText(contentEditableElement);//Select the entire contents of the element with the range
        range.collapse(false);//collapse the range to the end point. false means collapse to end rather than the start
        range.select();//Select the range (make it the visible selection
      }
    },
    stayFocused(id) {
      const p = document.getElementById(id)
      if (!this.isElementInViewport(p)) {
        console.log('is not in viewport')
        p.scrollIntoView({behavior: "auto", block: "end"})
      }

    },
    // todo put this method under utils
    isElementInViewport(el) {

      const rect = el.getBoundingClientRect();

      console.log(rect)
      console.log(document.documentElement.clientHeight * 0.65)

      return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight * 0.65 || document.documentElement.clientHeight * 0.65) && /* or $(window).height() */
          rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
      );
    },
    editComment(id, comment) {
      const payload = {
        sectionId: this.sectionId,
        commentId: id,
        request: {
          comment: comment,
        }
      }
      this.$store.dispatch('comment/editComment', payload)
          .then(res => {
            this.cancelFocus(id)
            this.quote = null
            this.editMode = false
            this.comment = null
            if (this.sectionId !== null) {
              this.fetchSectionComments(false)
            } else {
              this.fetchDocumentComments(false)
            }
          })

      this.editMode = true
      this.currentCommentId = id
      this.comment = comment
    },
    deleteComment(id) {
      const payload = {
        sectionId: this.sectionId,
        commentId: id
      }
      this.$store.dispatch('comment/deleteComment', payload)
          .then(res => {
            this.cancelFocus(id)
            this.quote = null
            this.comment = null
            if (this.sectionId !== null) {
              this.fetchSectionComments(false)
            } else {
              this.fetchDocumentComments(false)
            }
          })
    },
    getCurrentUserId() {
      const user = JSON.parse(localStorage.getItem('userData'))
      return user.userId
    }
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
    }
    return {
      perfectScrollbarSettings,
    }
  }
}

</script>
<style lang="scss" scoped>

[dir] .list-group-item {
  padding-left: .8rem;
  padding-right: .8rem;

  &:hover {
    background-color: rgba(9, 30, 66, 0.04);
  }
}

.comment-group-box {
  box-shadow: 0 0 1px 1px rgba(98, 98, 98, 0.104), 0 0 1px 1px rgba(98, 98, 98, 0.104);
}

.reply-editable-area {
  box-shadow: 0 0 1px 1px rgba(98, 98, 98, 0.104), 0 0 1px 1px rgba(98, 98, 98, 0.104);
  border-radius: 5px;
  padding: 8px 5px;
  height: auto;
  overflow-y: auto;
  line-height: 1em;
}

.reply-button {
  height: auto;
  font-size: 0.8em;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-right: 4px;
}

.cancel-button {
  font-size: 0.8em;
  height: auto;
  padding-top: 4px;
  padding-bottom: 4px;
}

.reply-editable-area:empty:before {
  content: attr(data-placeholder);
  color: #b8c2cc;
  font-size: .8em;
}

.reply-editable-area:not(:empty):focus::after {
  content: '';
  font-size: .8em;
}

.selected-section-header {
  border-bottom: 1px solid rgba(194, 196, 204, 0.3);
}

.selected-comment-div {
  border: 1px solid rgba(194, 196, 204, 0.3);
  border-radius: 5px;
}

.scroll-area::-webkit-scrollbar {
  width: 5px; /* Remove scrollbar space */
  background: transparent;

}

.scroll-area::-webkit-scrollbar-thumb {
  background: lightgrey;
}
</style>

