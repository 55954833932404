<template>
  <div class="row" style="padding: 5px;">
    <div class="col-md-12">
      <div class="container-fluid">
                <span>

                    </span>
        <span class="left-space text-center">
                    <strong>{{ username }}</strong>
                </span>
        <span class="left-space text-align-right">{{ user && user.fullName }}</span>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: 'mentionable-user',
  data() {
    return {
      dummyUsers: [
        {name: "mario", fullName: "Mario Speedwagon"},
        {name: "petey", fullName: "Petey Cruiser"},
        {name: "anna", fullName: "Anna Sthesia"},
        {name: "paul", fullName: "Paul Molive"},
        {name: "annaMull", fullName: "Anna Mull"},
        {name: "gail", fullName: "Gail Forcewind"},
        {name: "paige", fullName: "Paige Turner"},
        {name: "bob", fullName: "Bob Frapples"},
        {name: "walter", fullName: "Walter Melon"},
        {name: "nick", fullName: "Nick R. Bocker"},
        {name: "barb", fullName: "Barb Ackue"},
        {name: "buck", fullName: "Buck Kinnear"},
        {name: "greta", fullName: "Greta Life"},
        {name: "ira", fullName: "Ira Membrit"},
        {name: "shonda", fullName: "Shonda Leer"},
        {name: "brock", fullName: "Brock Lee"},
        {name: "maya", fullName: "Maya Didas"},
        {name: "rick", fullName: "Rick O'Shea"},
        {name: "pete", fullName: "Pete Sariya"},
        {name: "monty", fullName: "Monty Carlo"},
        {name: "sal", fullName: "Sal Monella"},
        {name: "sue", fullName: "Sue Vaneer"},
        {name: "cliff", fullName: "Cliff Hanger"},
        {name: "barb", fullName: "Barb Dwyer"}
      ]
    }
  },
  props: {
    size: {
      type: [Number, String],
      required: false
    },
    mention: {
      type: Object,
      required: false
    },
    showDefaultAvatar: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    username() {
      return this.mention.name;
    },
    user() {
      if (this.dummyUsers && this.dummyUsers.length > 0) {
        return this.dummyUsers.find(user => user.name === this.mention.name);
      } else {
        return [];
      }
    },
    sizeStyle() {
      const size = parseInt(this.size);
      if (size) {
        return {
          'min-width': `${size}px`,
          'width': `${size}px`,
          'min-height': `${size}px`,
          'height': `${size}px`,
          'line-height': `${size}px`
        }
      } else {
        return null;
      }
    }
  }
}
</script>

<style scoped>
.user {
  display: inline-block;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
}

.default-user-size {
  width: 35px;
  height: 35px;
  line-height: 35px;
}

.left-space {
  margin-left: 12px;
}

.text-align-right {
  text-align: right;
}
</style>
